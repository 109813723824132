<template>
  <div class="wrapper" :style="{ 'background-color': `#494949`}">
        <div class="main relative">
            <div class="header">
                <div class="top-info dsp-desktop">
                    <div class="main clearfix">
                      <div class="fr header-base"><img src="@/assets/images/header.jpg" width="100%"></div>
                      <div class="fr header-overlay"><img src="@/assets/images/header2.gif" width="100%"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="main clearfix">
                <div class="col-l">
                    <div>
                        <div>
                            <div id="hottoday">
                              <div><img class="responsive-image" src="@/assets/images/HanoistarExtra_Ad_4.jpg"></div>
                            </div>
                            <div>
                                <div class="my-result one-city--xosotv">
                                    <table data-title="" class="kqmb extendable">
                                        <tbody>
                                            <tr class="madb">
                                                <td colspan="12">
                                                    <div class="tit-mien tit-mienx clearfix">
                                                        <h2>Hà Nội ngôi sao: <span class="current-date">{{formatDate(this.first.datetime)}}</span></h2>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="db">
                                                <td class="txt-giai" style="color: inherit;">Đặc biệt</td>
                                                <td colspan="12" class="number"><span>
                                                        <b v-if="!loading[0]">
                                                            <span id="dice1">{{ this.first && this.first.five && this.first.five.charAt(0) }}</span>
                                                            <span id="dice2">{{ this.first && this.first.five && this.first.five.charAt(1) }}</span>
                                                            <span id="dice3">{{ this.first && this.first.five && this.first.five.charAt(2) }}</span>
                                                            <span id="dice4">{{ this.first && this.first.five && this.first.five.charAt(3) }}</span>
                                                            <span id="dice5">{{ this.first && this.first.five && this.first.five.charAt(4) }}</span>
                                                        </b>
                                                        <img src="@/assets/images/Spinner.gif" width="50" v-if="loading[0]">
                                                </span></td>
                                            </tr>
                                            <tr class="bg_f6">
                                                <td class="txt-giai">Giải nhất</td>
                                                <td colspan="12" class="number"><span>
                                                        <b v-if="!loading[1]">
                                                            <span id="dice6">{{ this.first && this.first.five2 && this.first.five2.charAt(0) }}</span>
                                                            <span id="dice7">{{ this.first && this.first.five2 && this.first.five2.charAt(1) }}</span>
                                                            <span id="dice8">{{ this.first && this.first.five2 && this.first.five2.charAt(2) }}</span>
                                                            <span id="dice9">{{ this.first && this.first.five2 && this.first.five2.charAt(3) }}</span>
                                                            <span id="dice10">{{ this.first && this.first.five2 && this.first.five2.charAt(4) }}</span>
                                                        </b>
                                                        <img src="@/assets/images/Spinner.gif" width="40" v-if="loading[1]">
                                                    </span></td>
                                            </tr>
                                            <tr>
                                                <td class="txt-giai">Giải nhì</td>
                                                <td colspan="6" class="number"><span>
                                                        <b v-if="!loading[2]">
                                                            <span id="dice11">{{ this.first && this.first.fivearray && this.first.fivearray[0] }}</span>
                                                        </b>
                                                        <img src="@/assets/images/Spinner.gif" width="40" v-if="loading[2]"></span></td>
                                                <td colspan="6" class="number"><span>
                                                        <b v-if="!loading[3]">
                                                            <span id="dice12">{{ this.first && this.first.fivearray && this.first.fivearray[1] }}</span>
                                                        </b>
                                                        <img src="@/assets/images/Spinner.gif" width="40" v-if="loading[3]"></span></td>
                                            </tr>
                                            <tr class="giai3 bg_ef">
                                                <td rowspan="2" class="txt-giai">Giải ba</td>
                                                <td colspan="4" class="number"><span>
                                                        <!---->
                                                        <!---->
                                                        <!----><b v-if="!loading[4]"><span id="dice13">{{ this.first && this.first.fivearray && this.first.fivearray[2] }}</span></b>
                                                        <img src="@/assets/images/Spinner.gif" width="40" v-if="loading[4]"></span></td>
                                                <td colspan="4" class="number"><span>
                                                        <!---->
                                                        <!---->
                                                        <!----><b v-if="!loading[5]"><span id="dice14">{{ this.first && this.first.fivearray && this.first.fivearray[3] }}</span></b>
                                                        <img src="@/assets/images/Spinner.gif" width="40" v-if="loading[5]"></span></td>
                                                <td colspan="4" class="number"><span>
                                                        <!---->
                                                        <!---->
                                                        <!----><b v-if="!loading[6]"><span id="dice15">{{ this.first && this.first.fivearray && this.first.fivearray[4] }}</span></b>
                                                        <img src="@/assets/images/Spinner.gif" width="40" v-if="loading[6]"></span></td>
                                            </tr>
                                            <tr class="bg_ef">
                                                <td colspan="4" class="number"><span>
                                                        <!---->
                                                        <!---->
                                                        <!----><b v-if="!loading[7]"><span id="dice16">{{ this.first && this.first.fivearray && this.first.fivearray[5] }}</span></b>
                                                        <img src="@/assets/images/Spinner.gif" width="40" v-if="loading[7]"></span></td>
                                                <td colspan="4" class="number"><span>
                                                        <!---->
                                                        <!---->
                                                        <!----><b v-if="!loading[8]"><span id="dice17">{{ this.first && this.first.fivearray && this.first.fivearray[6] }}</span></b>
                                                        <img src="@/assets/images/Spinner.gif" width="40" v-if="loading[8]"></span></td>
                                                <td colspan="4" class="number"><span>
                                                        <!---->
                                                        <!---->
                                                        <!----><b v-if="!loading[9]"><span id="dice18">{{ this.first && this.first.fivearray && this.first.fivearray[7] }}</span></b>
                                                        <img src="@/assets/images/Spinner.gif" width="40" v-if="loading[9]"></span></td>
                                            </tr>
                                            <tr>
                                                <td class="txt-giai">Giải tư</td>
                                                <td colspan="3" class="number"><span>
                                                        <!---->
                                                        <!---->
                                                        <!----><b v-if="!loading[10]"><span id="dice19">{{ this.first && this.first.fourarray && this.first.fourarray[0] }}</span></b>
                                                        <img src="@/assets/images/Spinner.gif" width="35" v-if="loading[10]"></span></td>
                                                <td colspan="3" class="number"><span>
                                                        <!---->
                                                        <!---->
                                                        <!----><b v-if="!loading[11]"><span id="dice20">{{ this.first && this.first.fourarray && this.first.fourarray[1] }}</span></b>
                                                        <img src="@/assets/images/Spinner.gif" width="35" v-if="loading[11]"></span></td>
                                                <td colspan="3" class="number"><span>
                                                        <!---->
                                                        <!---->
                                                        <!----><b v-if="!loading[12]"><span id="dice21">{{ this.first && this.first.fourarray &&this.first.fourarray[2] }}</span></b>
                                                        <img src="@/assets/images/Spinner.gif" width="35" v-if="loading[12]"></span></td>
                                                <td colspan="3" class="number"><span>
                                                        <!---->
                                                        <!---->
                                                        <!----><b v-if="!loading[13]"><span id="dice22">{{ this.first && this.first.fourarray &&this.first.fourarray[3] }}</span></b>
                                                        <img src="@/assets/images/Spinner.gif" width="35" v-if="loading[13]"></span></td>
                                            </tr>
                                            <tr class="giai5 bg_ef">
                                                <td rowspan="2" class="txt-giai">Giải năm</td>
                                                <td colspan="4" class="number"><span>
                                                        <!---->
                                                        <!---->
                                                        <!----><b v-if="!loading[14]"><span id="dice23">{{ this.first && this.first.fourarray &&this.first.fourarray[4] }}</span></b>
                                                        <img src="@/assets/images/Spinner.gif" width="35" v-if="loading[14]"></span></td>
                                                <td colspan="4" class="number"><span>
                                                        <!---->
                                                        <!---->
                                                        <!----><b v-if="!loading[15]"><span id="dice24">{{ this.first && this.first.fourarray &&this.first.fourarray[5] }}</span></b>
                                                        <img src="@/assets/images/Spinner.gif" width="35" v-if="loading[15]"></span></td>
                                                <td colspan="4" class="number"><span>
                                                        <!---->
                                                        <!---->
                                                        <!----><b v-if="!loading[16]"><span id="dice25">{{ this.first && this.first.fourarray &&this.first.fourarray[6] }}</span></b>
                                                        <img src="@/assets/images/Spinner.gif" width="35" v-if="loading[16]"></span></td>
                                            </tr>
                                            <tr class="bg_ef">
                                                <td colspan="4" class="number"><span>
                                                        <!---->
                                                        <!---->
                                                        <!----><b v-if="!loading[17]"><span id="dice26">{{ this.first && this.first.fourarray &&this.first.fourarray[7] }}</span></b>
                                                        <img src="@/assets/images/Spinner.gif" width="35" v-if="loading[17]"></span></td>
                                                <td colspan="4" class="number"><span>
                                                        <!---->
                                                        <!---->
                                                        <!----><b v-if="!loading[18]"><span id="dice27">{{ this.first && this.first.fourarray &&this.first.fourarray[8] }}</span></b>
                                                        <img src="@/assets/images/Spinner.gif" width="35" v-if="loading[18]"></span></td>
                                                <td colspan="4" class="number"><span>
                                                        <!---->
                                                        <!---->
                                                        <!----><b v-if="!loading[19]"><span id="dice28">{{ this.first && this.first.fourarray &&this.first.fourarray[9] }}</span></b>
                                                        <img src="@/assets/images/Spinner.gif" width="35" v-if="loading[19]"></span></td>
                                            </tr>
                                            <tr>
                                                <td class="txt-giai">Giải sáu</td>
                                                <td colspan="4" class="number"><span>
                                                        <!---->
                                                        <!---->
                                                        <!----><b v-if="!loading[20]"><span id="dice29">{{ this.first && this.first.threearray && this.first.threearray[0] }}</span></b>
                                                        <img src="@/assets/images/Spinner.gif" width="38" v-if="loading[20]"></span></td>
                                                <td colspan="4" class="number"><span>
                                                        <!---->
                                                        <!---->
                                                        <!----><b v-if="!loading[21]"><span id="dice30">{{ this.first && this.first.threearray && this.first.threearray[1] }}</span></b>
                                                        <img src="@/assets/images/Spinner.gif" width="38" v-if="loading[21]"></span></td>
                                                <td colspan="4" class="number"><span>
                                                        <!---->
                                                        <!---->
                                                        <!----><b v-if="!loading[22]"><span id="dice31">{{ this.first && this.first.threearray && this.first.threearray[2] }}</span></b>
                                                        <img src="@/assets/images/Spinner.gif" width="38" v-if="loading[22]"></span></td>
                                            </tr>
                                            <tr class="db bg_ef">
                                                <td class="txt-giai" style="color: inherit;">Giải bảy</td>
                                                <td colspan="3" class="number"><span>
                                                        <!---->
                                                        <!---->
                                                        <!----><b v-if="!loading[23]"><span id="dice32">{{ this.first && this.first.twoarry && this.first.twoarry[0] }}</span></b>
                                                        <img src="@/assets/images/Spinner.gif" width="38" v-if="loading[23]"></span></td>
                                                <td colspan="3" class="number"><span>
                                                        <!---->
                                                        <!---->
                                                        <!----><b v-if="!loading[24]"><span id="dice33">{{ this.first && this.first.twoarry && this.first.twoarry[1] }}</span></b>
                                                        <img src="@/assets/images/Spinner.gif" width="38" v-if="loading[24]"></span></td>
                                                <td colspan="3" class="number"><span>
                                                        <!---->
                                                        <!---->
                                                        <!----><b v-if="!loading[25]"><span id="dice34">{{ this.first && this.first.twoarry && this.first.twoarry[2] }}</span></b>
                                                        <img src="@/assets/images/Spinner.gif" width="38" v-if="loading[25]"></span></td>
                                                <td colspan="3" class="number"><span>
                                                        <!---->
                                                        <!---->
                                                        <!----><b v-if="!loading[26]"><span id="dice35">{{ this.first && this.first.twoarry && this.first.twoarry[3] }}</span></b>
                                                        <img src="@/assets/images/Spinner.gif" width="38" v-if="loading[26]"></span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div id="hottoday">
                              <div><img class="responsive-image" src="@/assets/images/HanoistarExtra_Ad_9.jpg"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-center">
                    <div>
                      <div style="display:none;">Kết quả xổ số Hôm Nay</div>
                        <div id="ketquaxosohomnay">
                            <div><img class="responsive-image" src="@/assets/images/5G-News-1.jpg"></div>
                            <div><img class="responsive-image" src="@/assets/images/5G-News-2.jpg"></div>
                            <div><img class="responsive-image" src="@/assets/images/5G-News-3.jpg"></div>
                      </div>
                    </div>
                </div>
                <div class="col-right">
                    <div class="conten-right txt-center">
                        <div ><a href="#" title="Hanoi Extra" target="_blank"><img src="@/assets/images/5G-Ad-1.gif" style="max-width: 100%;"></a></div>
                        <div ><a href="#" title="Hanoi Extra" target="_blank"><img src="@/assets/images/5G-Ad-2.jpg" style="max-width: 100%;"></a></div>
                        <div ><a href="#" title="Hanoi Extra" target="_blank"><img src="@/assets/images/5G-Ad-3.jpg" style="max-width: 100%;"></a></div>
                    </div>
                </div>
            </div>
            <div class="main clearfix"
                style="margin-top: 15px; background-color: #c33b2b; padding: 15px; text-align: center; color: #FFF; font-size: 120%;">
                HanoistarEXTRA.com</div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/app'
import moment from 'moment-timezone'
import axios from 'axios'
export default {
  name: 'Home',
  async mounted() {
    await this.initializeServerTime();
    this.getLotto()
    setInterval(this.getLastData, 1000)
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: `url(${this.imageUrl})`,
        backgroundPosition: 'center top',
        backgroundSize: '520px 40px',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'initial',
        backgroundOrigin: 'initial',
        backgroundClip: 'initial',
        backgroundColor: 'rgb(255, 255, 255) !important',
      };
    },
  },
  data () {
    return {
      serverTime: null,
      timeOffset: 0,
      imageUrl: require('@/assets/images/bar2.png'),
      AniDiceRandom: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
        10: false,
        11: false,
        12: false,
        13: false,
        14: false,
        15: false,
        16: false,
        17: false,
        18: false,
        19: false,
        20: false,
        21: false,
        22: false,
        23: false,
        24: false,
        25: false,
        26: false
      },
      AniDiceRandom2: false,
      dic: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0
      },
      pictureUrl: '',
      first: '',
      data: [],
      banner: [],
      seconds: 0,
      countdown: '',
      nextday: '',
      selectdate: '',
      loading: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
        10: false,
        11: false,
        12: false,
        13: false,
        14: false,
        15: false,
        16: false,
        17: false,
        18: false,
        19: false,
        20: false,
        21: false,
        22: false,
        23: false,
        24: false,
        25: false,
        26: false
      }
    }
  },
  methods: {
    AniDice (dic) {
      this.dic[dic] = setInterval(this.rolldice, 100, dic)
    },
    AniDice5 (dic) {
      this.dic[dic] = setInterval(this.rolldice5, 100, dic)
    },
    AniDice4 (dic) {
      this.dic[dic] = setInterval(this.rolldice4, 100, dic)
    },
    AniDice3 (dic) {
      this.dic[dic] = setInterval(this.rolldice3, 100, dic)
    },
    AniDice2 (dic) {
      this.dic[dic] = setInterval(this.rolldice2, 100, dic)
    },
    rolldice (dic) {
      var ranNum = Math.floor(Math.random() * 10)
      document.getElementById('dice' + dic).innerHTML = ranNum
    },
    rolldice2 (dic) {
      var ranNum = Math.floor(Math.random() * 100).toString().padStart(2, '0')
      document.getElementById('dice' + dic).innerHTML = ranNum
    },
    rolldice3 (dic) {
      var ranNum = Math.floor(Math.random() * 1000).toString().padStart(3, '0')
      document.getElementById('dice' + dic).innerHTML = ranNum
    },
    rolldice4 (dic) {
      var ranNum = Math.floor(Math.random() * 10000).toString().padStart(4, '0')
      document.getElementById('dice' + dic).innerHTML = ranNum
    },
    rolldice5 (dic) {
      var ranNum = Math.floor(Math.random() * 100000).toString().padStart(5, '0')
      document.getElementById('dice' + dic).innerHTML = ranNum
    },
    stopDice (dic) {
      clearInterval(this.dic[dic])
      if (dic == 1) {
        document.getElementById('dice' + dic).innerHTML = this.first.five.charAt(0)
      } else if (dic == 2) {
        document.getElementById('dice' + dic).innerHTML = this.first.five.charAt(1)
      } else if (dic == 3) {
        document.getElementById('dice' + dic).innerHTML = this.first.five.charAt(2)
      } else if (dic == 4) {
        document.getElementById('dice' + dic).innerHTML = this.first.five.charAt(3)
      } else if (dic == 5) {
        document.getElementById('dice' + dic).innerHTML = this.first.five.charAt(4)
      } else if (dic == 6) {
        document.getElementById('dice' + dic).innerHTML = this.first.five2.charAt(0)
      } else if (dic == 7) {
        document.getElementById('dice' + dic).innerHTML = this.first.five2.charAt(1)
      } else if (dic == 8) {
        document.getElementById('dice' + dic).innerHTML = this.first.five2.charAt(2)
      } else if (dic == 9) {
        document.getElementById('dice' + dic).innerHTML = this.first.five2.charAt(3)
      } else if (dic == 10) {
        document.getElementById('dice' + dic).innerHTML = this.first.five2.charAt(4)
      } else if (dic >= 11 && dic <= 18) {
        document.getElementById('dice' + dic).innerHTML = this.first.fivearray[Number(dic) - 11]
      } else if (dic >= 19 && dic <= 28) {
        document.getElementById('dice' + dic).innerHTML = this.first.fourarray[Number(dic) - 19]
      } else if (dic >= 29 && dic <= 31) {
        document.getElementById('dice' + dic).innerHTML = this.first.threearray[Number(dic) - 29]
      } else if (dic >= 32 && dic <= 35) {
        document.getElementById('dice' + dic).innerHTML = this.first.twoarry[Number(dic) - 32]
      }
    },
    async getBackGround () {
      const background = (await firebase.database().ref('/background4').once('value')).val()
      if (background) { this.pictureUrl = background.image }
    },
    async getBanners () {
      this.banner = (await firebase.database().ref('/banners/4').once('value')).val()
    },
    async getTimeServer() {
    try {
      const response = await axios.get('https://us-central1-lotto-landingpage2.cloudfunctions.net/gettimezone');
      return response.data.datetime;
    } catch (error) {
      try {
        const response = await axios.get('https://worldtimeapi.org/api/timezone/Asia/Bangkok');
        return response.data.datetime;
      } catch (error) {
        console.error(error);
        return new Date().toISOString(); // fallback ใช้เวลาของเครื่อง
      }
    }
  },

  // เพิ่ม method ใหม่
  async initializeServerTime() {
    const serverDateTime = await this.getTimeServer();
    const serverTime = new Date(serverDateTime);
    const localTime = new Date();
    this.timeOffset = serverTime.getTime() - localTime.getTime();
    this.serverTime = serverTime;
  },

  // เพิ่ม method ใหม่
  getCurrentServerTime() {
    if (!this.serverTime) return new Date();
    return new Date(new Date().getTime() + this.timeOffset);
  },
    async getLotto () {
      const currentTime = this.getCurrentServerTime();
    const timezone = currentTime.toISOString();
      let data = (await firebase.database().ref('/huay/32').orderByChild('datetime').limitToLast(3).once('value')).val()
      data = _.orderBy(data, ['datetime'], ['desc'])
      if (data) {
        for (const x in data) {
          const d = data[x].datetime.split(' ')
          const date = d[0].split('-')
          const time = d[1].split(':')
          const datetime = Number(date[0] + '' + date[1] + '' + date[2] + '' + time[0] + '' + time[1])
          if (datetime > Number(moment(timezone).format('YYYYMMDDHHmm'))) {
            continue
          }
          if (!this.first) { this.first = data[x] }
          this.data.push(data[x])
        }
      }
      setInterval(this.getLastData, 1000)
    },
    async getFullYear () {
      const timezone  = await this.getTimeServer()
      return moment(timezone).format('YYYY')
    },
    formatDate (date) {
      return moment(date).tz('Asia/Bangkok').format('DD/MM/YYYY')
    },
    formatTime (date) {
      if (date) { return moment(date).tz('Asia/Bangkok').format('HH:mm') }
    },
    dateToday () {
      return moment().tz('Asia/Bangkok').format('dddd, DD MMMM YYYY')
    },
    async getLottoByDate (date) {
      const timezone = await this.getTimeServer()
      const d = date.split('/')
      let datemoment = moment(d[2] + '-' + d[0] + '-' + d[1]).tz('Asia/Bangkok').format('YYYY-MM-DD')
      const today = moment(timezone).format('YYYY-MM-DD')
      if (Number(moment(timezone).format('YYYYMMDD')) < Number(moment(d[2] + '-' + d[0] + '-' + d[1]).tz('Asia/Bangkok').format('YYYYMMDD'))) {
        return
      }
      if (datemoment === today && Number(moment(timezone).format('HHmm')) < 845) {
        datemoment = moment(timezone).add(-1, 'days').format('YYYY-MM-DD')
      } else if (moment(datemoment).format('YYYYMMDD') > moment(today).format('YYYYMMDD')) {
        datemoment = moment(timezone).add(-1, 'days').format('YYYY-MM-DD')
      }else{
        for (const x in this.loading) {
          this.loading[x] = false
        }
      }
      // console.log(datemoment);
      this.selectdate = datemoment
      const data = (await firebase.database().ref('/huay/32').orderByChild('datetime').startAt(datemoment).endAt(datemoment + ' 23:59').once('value')).val()
      if (data) {
        for (const x in data) {
          this.first = data[x]
          break
        }
      }
    },
    async getLastData () {
      const currentTime = this.getCurrentServerTime()
      const timezone = currentTime.toISOString()
      const datas = (await firebase.database().ref('/huay/32').orderByChild('datetime').startAt(moment(timezone).format('YYYY-MM-DD HH:mm')).limitToFirst(1).once('value')).val()
      if (datas) {
        for (const x in datas) {
          const data = datas[x]
          if (data && data.datetime === moment(timezone).format('YYYY-MM-DD HH:mm')) {
            if (this.first.five !== data.five && (this.selectdate === moment(timezone).format('YYYY-MM-DD') || !this.selectdate)) {
              this.first = data
              for (const x in this.loading) {
                this.loading[x] = false
              }
            }
          } else if ((this.selectdate === moment(timezone).format('YYYY-MM-DD') || !this.selectdate)) {
            const t1 = moment(timezone)
            const t2 = moment.tz(data.datetime+':00','Asia/Bangkok')
            const diff = t2.diff(t1, 'seconds')
            // $('#clock-lottery').FlipClock(diff, {
            //   countdown: true
            // });
            // console.log(diff);
            this.nextday = data
            this.renderCountDown(data.datetime)
            if (diff <= 2700) {
              for (const x in this.loading) {
                this.loading[x] = true
              }
              this.first.datetime = data.datetime
            }
            if (diff <= 900) {
              this.first = data
              // แถวสอง
              // 5 ตัว
              for (let i = 0; i < 8; i++) {
                const start = 900 - (i * 30)
                const end = 860 - (i * 30)
                this.checkAniDice(data, diff, start, end, 11 + i, 2 + i, 2 + i, 5)
              }
              // 4 ตัว
              for (let i = 0; i < 10; i++) {
                const start = 650 - (i * 30)
                const end = 620 - (i * 30)
                this.checkAniDice(data, diff, start, end, 19 + i, 10 + i, 10 + i, 4)
              }
              // 3 ตัว
              for (let i = 0; i < 3; i++) {
                const start = 350 - (i * 30)
                const end = 320 - (i * 30)
                this.checkAniDice(data, diff, start, end, 29 + i, 20 + i, 20 + i, 3)
              }
              // 2 ตัว
              for (let i = 0; i < 4; i++) {
                const start = 260 - (i * 30)
                const end = 230 - (i * 30)
                this.checkAniDice(data, diff, start, end, 32 + i, 23 + i, 23 + i, 2)
              }
              if (diff <= 140 && this.AniDiceRandom[1] === false) {
                this.AniDiceRandom[1] = true
                this.AniDice(6)
                this.AniDice(7)
                this.AniDice(8)
                this.AniDice(9)
                this.AniDice(10)
              }
              if (diff <= 120) {
                this.loading[1] = false
              }
              if (diff <= 119) {
                this.stopDice(6)
              }
              if (diff <= 118) {
                this.stopDice(7)
              }
              if (diff <= 117) {
                this.stopDice(8)
              }
              if (diff <= 116) {
                this.stopDice(9)
              }
              if (diff <= 115) {
                this.stopDice(10)
              }

              if (diff <= 115) {
                this.loading[0] = false
              }
              if (diff <= 115 && this.AniDiceRandom[0] === false) {
                this.AniDiceRandom[0] = true
                this.AniDice(1)
                this.AniDice(2)
                this.AniDice(3)
                this.AniDice(4)
                this.AniDice(5)
              } else if (diff === 1) {
                setTimeout(() => {
                  this.stopDice(1)
                }, 1000)
                setTimeout(() => {
                  this.stopDice(2)
                }, 2000)
                setTimeout(() => {
                  this.stopDice(3)
                }, 3000)
                setTimeout(() => {
                  this.stopDice(4)
                }, 4000)
                setTimeout(() => {
                  this.stopDice(5)
                }, 5000)
              }
            }
            // แถวสาม
          }
        }
      }
    },
    checkAniDice (data, diff, start, end, dic, check, load, digit) {
      if (diff <= start) {
        this.loading[load] = false
        this.first.fivearray = data.fivearray
      }
      if (diff <= start && this.AniDiceRandom[check] === false) {
        this.AniDiceRandom[check] = true
        if (digit === 5) { this.AniDice5(dic) } else if (digit === 4) { this.AniDice4(dic) } else if (digit === 3) { this.AniDice3(dic) } else if (digit === 2) { this.AniDice2(dic) }
      }
      if (diff <= end) {
        this.stopDice(dic)
      }
    },
    renderCountDown (datetime) {
      // Find the distance between now and the count down date
      // const t1 = moment().tz('Asia/Bangkok')
      // const t2 = moment(datetime).tz('Asia/Bangkok')
      // const distance = t2.diff(t1, 'seconds')
      // console.log(distance);
      const date2 = moment(datetime);
      const now = moment();
      const duration = moment.duration(date2.diff(now));
      // Time calculations for days, hours, minutes and seconds
      var hours = ('0' + duration.hours()).slice(-2)
      var minutes = ('0' + duration.minutes()).slice(-2)
      var seconds = ('0' + duration.seconds()).slice(-2)
      // Display the result in an element with id="demo"
      this.countdown = hours + ':' + minutes + ':' + seconds

      // If the count down is finished, write some text
    },
    async initializeServerTime() {
      const serverDateTime = await this.getTimeServer();
      const serverTime = new Date(serverDateTime);
      const localTime = new Date();
      this.timeOffset = serverTime.getTime() - localTime.getTime();
      this.serverTime = serverTime;
    },
    getCurrentServerTime() {
      if (!this.serverTime) return new Date();
      return new Date(new Date().getTime() + this.timeOffset);
    }
  }
}
</script>
<style scoped>
span.bol30 {
    font-family: Arial,Helvetica,sans-serif;
}
span.bol30_0 {
    background-position: left 0;
}
span.bol30_1 {
    background-position: left -32px;
}
span.bol30_2 {
    background-position: left -64px;
}
span.bol30_3 {
    background-position: left -97px;
}
span.bol30_4 {
    background-position: left -130px;
}
span.bol30_5 {
    background-position: left -162px;
}
span.bol30_6 {
    background-position: left -194px;
}
span.bol30_7 {
    background-position: left -226px;
}
span.bol30_8 {
    background-position: left -259px;
}
span.bol30_9 {
    background-position: left -291px;
}
span.bol30 {
    background-image: url(https://mlnhngoc.net/template/2016/images/glass-balls-30px.png);
    background-repeat: no-repeat;
    font-size: 16px;
    height: 32px;
    width: 30px;
    display: inline-block;
    border: 0;
    position: static;
    text-align: center;
    color: #000;
}
div.box_tkdefault_loto h4.box_tkdefault_title {
    background: url(https://mlnhngoc.net/template/2016/images/rand.png) top #999;
    line-height: 25px;
    padding-left: 10px;
    border: 1px solid #333;
    text-align: center;
    text-transform: uppercase;
    color: #FFF;
    border-radius: 5px 5px 0 0;
    margin-bottom: 0px;
}
.box_tkdefault_xhn3l .box_tkdefault_title {
    border-bottom: 1px solid #E9E9E9;
    font-size: 12px;
}
div.box_tkdefault_loto table {
    border: 1px solid #333;
    background-color: #FFF;
}
tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}
user agent stylesheet
table {
    border-collapse: separate;
    text-indent: initial;
    border-spacing: 2px;
}
#dnw-main, table td {
    font-size: 12px;
    line-height: 1.5;
}

#dnw-main, #topmenu_mien_home li a, .dnw-sheet-body, .info_slide *, .sodauduoi_miennam .kq, input, select, table td, textarea {
    font-family: Arial,Helvetica,sans-serif;
}
div.box_tkdefault_loto table tr.header td {
    background: #CCC;
    height: 20px;
    line-height: 20px;
}
div.box_tkdefault_loto table td {
    border-bottom: 1px solid #CCC;
    text-align: center;
    padding: 2px;
}
.dnw-content-layout td {
    vertical-align: top;
}
div.box_tkdefault_loto table td {
    border-bottom: 1px solid #CCC;
    text-align: center;
    padding: 2px;
}
#thongkexxdau .chiso {
    float: left;
    background: url(https://mlnhngoc.net/images/rand.png) #a60062;
    height: 10px;
}
.dnw-content-layout .main div, .dnw-content-layout .main table {
    max-width: 520px;
}
.box_tkdefault_xhn3l .chiso, ul.menumember {
    margin-top: 10px;
}
.modulesLR.moduleBrown .title-l {
    background: #EEE;
    color: #000;
    border: 1px solid #9E9E9E;
    border-top: 0;
}
.modulesLR .title-l, .modulesLR .title-l_red {
    border-radius: 5px 5px 0 0;
    margin: -5px -1px 0;
}
#ketquaxosohomnay {
    position: relative;
    min-height: 100px;
}
a.next-day {
    position: absolute;
    top: 7px;
    left: 3px;
    border: 1px solid #a1a1a1;
    width: 22px;
    height: 22px;
    text-align: center;
    line-height: 22px;
    border-radius: 50%;
    color: #a1a1a1;
}
a.previous-day {
    position: absolute;
    top: 7px;
    right: 3px;
    border: 1px solid #a1a1a1;
    width: 22px;
    height: 22px;
    text-align: center;
    line-height: 22px;
    border-radius: 50%;
    color: #a1a1a1;
}
.modulesLR.moduleBrown .title-r2 h1, .modulesLR.moduleBrown .title-r2 h1 a {
    color: #000;
}
.modulesLR .title-r2 h1 {
    padding: 5px 10px;
}
.modulesLR .title-l, .modulesLR .title-l_red {
    border-radius: 5px 5px 0 0;
    margin: -5px -1px 0;
}
.modulesLR .title-r2 h1, .modulesLR .title-r2 h1 a {
    color: #fff;
    font-size: 12px;
    text-align: left;
    font-weight: 700;
}
.modulesLR.moduleBrown {
    background: #F3F3F3;
    border: 1px solid #9E9E9E;
}
.modulesLR {
    display: block;
    border: 1px solid #EEE;
    border-radius: 5px;
    background: #F9F9F9;
    margin: 0;
    padding: 5px 0;
}
.box_kqxs div img, .box_kqxs div.Menutool img, .box_kqxs td, .dnw-content-layout .box_kqxs td, .modulesLR .title-r2, div.Menutool img, input, select, span.bol30, textarea {
    vertical-align: middle;
}
ul.menu.menulichxoso li a {
    background: url(https://www.minhngoc.net.vn/template/2016/images/arrow_down_gold.png) left center no-repeat;
    padding-left: 15px;
}
ul.menu li a.hottoday_mien, ul.menu2 li a.hottoday_tinh7 {
    font-weight: 400;
}
ul.menu li a {
    background: url(https://www.minhngoc.net.vn/template/2016/images/arrow_1.gif) left center no-repeat;
    padding: 3px 0 3px 10px;
}
#hottoday .finnish, ul.menu.menu .finnish, ul.menu2 .finnish {
    background: url(https://www.minhngoc.net.vn/template/2016/images/ok_daxosoxong.png) right center no-repeat;
}
#hottoday .icon, ul.menu .icon, ul.menu2 .icon {
    display: block;
}

.boxdauduoimien td, .dauduoi td, ul.menu li ul li a, ul.menu2 li ul li a {
    font-family: Arial,Helvetica,sans-serif;
}
ul.menu li ul li a, ul.menu2 li ul li a {
    text-decoration: none;
    font-size: 11px;
    color: #333;
    display: block;
    font-weight: 400;
    white-space: nowrap;
    padding: 3px 0;
}
ul.menu li a, ul.menu2 li a {
    font-size: 11px;
    color: #333;
    display: block;
    font-weight: 700;
    white-space: nowrap;
}
ul.menu li a, ul.menu2 li a {
    font-size: 11px;
    color: #333;
    display: block;
    font-weight: 700;
    white-space: nowrap;
}
ul.menu.menulichxoso li ul li a {
    background: url(https://www.minhngoc.net.vn/template/2016/images/arrow_1.gif) 5px center no-repeat;
    padding-left: 15px;
}
#hottoday .border {
    border: none;
}
#box_tracuukqxs input, #box_tracuukqxs select, #box_tracuukqxs td, #hottoday * {
    font-size: 11px;
}
.dnw-content-layout .main div, .dnw-content-layout .main table {
    max-width: 520px;
}
.responsive-image {
  width: 100%;
  height: auto;
}
.header-container {
    position: relative;
    width: 100%;
}

.header-base {
    width: 100%;
}

.header-overlay {
    position: absolute;
    right: 0;
    top: 0;
    width: 50%; /* ปรับขนาดตามต้องการ */
}
</style>
